import { fields } from "@monorepo/utils/src/variables/projectsData/eadView/fields";
import { ViewMode } from "@monorepo/utils/src/types/viewMode";
import { searchMenuItems } from "@monorepo/inventory/src/views/EadView/constants/searchMenuItems";

export const filtersElements = [
  {
    value: fields.FILTER_KEYWORD,
    //title: "Поиск",
    placeholder: "Поисковый запрос",
    tooltip: "Ручной ввод, поиск по маске",
    type: "autocomplete",
    showSelectedKey: "code",
    autocompleteActionName: "getSearchAutocomplete",
    className: "filters__full-block",
    availableDocumentTypes: [ViewMode.TABLE, ViewMode.SEARCH],
    appendInnerIcon: "mdi-cog",
    iconTooltip: "Найденные фрагменты будут подсвечены в результатах поиска соответствующим найстройке поиска цветом",
    menuItems: searchMenuItems,
  },
  {
    value: fields.FILTER_TITLE,
    title: "Заголовок",
    tooltip: "Ручной ввод, поиск по маске",
    type: "text",
    width: 280,
    availableDocumentTypes: [ViewMode.TABLE, ViewMode.SEARCH],
  },
  {
    value: fields.FILTER_REG_NUMBER,
    title: "Регистрационный номер документа в СЭД",
    tooltip: "Ручной ввод, поиск по маске",
    type: "text",
    width: 300,
    availableDocumentTypes: [ViewMode.TABLE, ViewMode.SEARCH],
  },
  {
    value: fields.FILTER_DATE_RECEIVED,
    dateValues: [fields.FILTER_DATE_RECEIVED_FROM, fields.FILTER_DATE_RECEIVED_TO],
    timeValues: [fields.FILTER_TIME_RECEIVED_FROM, fields.FILTER_TIME_RECEIVED_TO],
    title: "Дата и время поступления в ЦХЭД",
    tooltip: "Выбор интервала с помощью календаря",
    availableDocumentTypes: [ViewMode.TABLE, ViewMode.SEARCH],
    type: "dateTimePeriod",
    width: 392,
  },
  {
    value: fields.FILTER_CIPHER_DOC,
    title: "Архивный шифр документа",
    tooltip: "Ручной ввод, поиск по маске",
    type: "text",
    width: 280,
    availableDocumentTypes: [ViewMode.TABLE, ViewMode.SEARCH],
  },
  {
    value: fields.FILTER_CIPHER_CASE,
    title: "Архивный шифр дела",
    tooltip: "Ручной ввод, поиск по маске",
    type: "text",
    width: 280,
    availableDocumentTypes: [ViewMode.TABLE, ViewMode.SEARCH],
  },
  {
    value: fields.FILTER_INDEX,
    title: "Индекс дела",
    tooltip: "Ручной ввод, поиск по маске",
    type: "text",
    width: 280,
    availableDocumentTypes: [ViewMode.TABLE, ViewMode.SEARCH],
  },
  {
    value: fields.FILTER_TITLE_CASE,
    title: "Заголовок дела",
    tooltip: "Ручной ввод, поиск по маске",
    type: "text",
    width: 280,
    availableDocumentTypes: [ViewMode.TABLE, ViewMode.SEARCH],
  },
  {
    value: fields.FILTER_OIK,
    title: "ОИК",
    tooltip: "Выбор из списка с предиктивным вводом",
    type: "autocomplete",
    showSelectedKey: "code",
    autocompleteActionName: "getOikAutocompleteItems",
    width: 300,
  },
  {
    value: fields.FILTER_FUND,
    title: "Фонд",
    tooltip: "Выбор из списка с предиктивным вводом",
    type: "autocomplete",
    showSelectedKey: "number",
    autocompleteActionName: "getAutocompleteFilterByCode",
    width: 300,
  },
  {
    value: fields.FILTER_CHIPBOARD,
    title: "ДСП",
    tooltip: "Учитывать/не учитывать признак ДСП",
    type: "select",
    selectItemsField: "chipboardItems",
    width: 250,
  },
  {
    value: fields.FILTER_STATUS,
    title: "Статус",
    tooltip: "Выбор из списка: Получен, Не получен, Удалён",
    type: "select",
    selectItemsField: "statusOptions",
    width: 250,
  },
  {
    value: fields.FILTER_DOC_TYPE,
    title: "Перечень видов документов",
    tooltip: "Ручной ввод, поиск по маске",
    type: "treeFilter",
    width: 380,
    selectItemsField: "sectionsTreeItems",
    availableDocumentTypes: [ViewMode.TABLE, ViewMode.SEARCH],
  },
];
