











































































































import { defineComponent } from "@vue/composition-api";
import TextFilter from "@monorepo/uikit/src/components/tableViews/TextFilter.vue";
import { IEadDocumentFiles } from "@monorepo/inventory/src/views/EadView/types/eadElement";
import { environmentVariables } from "@monorepo/utils/src/variables/environmentVariables";
import { getFileSize } from "@monorepo/utils/src/utils/getFileSize";
import { createLogger, mapActions, mapGetters } from "vuex";
import TableSearchResults from "@monorepo/uikit/src/components/common/TableSearchResults.vue";
import { fields } from "@monorepo/utils/src/variables/projectsData/eadView/fields";
import Vue from "vue";
import { searchMenuItems } from "@monorepo/inventory/src/views/EadView/constants/searchMenuItems";
import { PreviewStatuses } from "@monorepo/inventory/src/constants/previewStatuses";

interface IHeader {
  width: number;
  value: string;
  text: string;
  tooltip?: string;
}

interface IResultData {
  id: string;
  title: string;
  extension: string;
  size: string;
  attachedTo?: string;
  previewStatusCode?: string;
  pages?: unknown[];
  deleted: boolean;
  status: string;
  type: string;
  uploadFileGuid: string;
  isSig: boolean;
  previewStatusTitle?: string;
}

export default defineComponent({
  name: "EadFilesTable",
  components: {
    TextFilter,
    TableSearchResults,
  },
  data() {
    return {
      textFilter: "",
      isSearchLoading: false,
      highlightFields: {},
      iconSrc: `${environmentVariables.BASE_URL}assets/images/ead/file-document-check.svg`,
      menu: false,
      filters: {
        [fields.FILTER_SEARCH_MORPH]: true,
      },
      menuItems: searchMenuItems,
      PreviewStatuses,
    };
  },
  props: {
    files: {
      type: Array,
      default() {
        return [];
      },
    },
    id: {
      type: [String, Number],
      default() {
        return 0;
      },
    },
  },
  computed: {
    ...mapGetters("eadView", ["elasticValue"]),
    isShowRefreshPreview() {
      return (file: IResultData) => {
        return !file.deleted && [PreviewStatuses.FAILED, PreviewStatuses.DELETED].includes(file.previewStatusCode as PreviewStatuses);
      };
    },
    headers(): IHeader[] {
      return [
        {
          width: 100,
          value: "type",
          text: "Тип файла",
          tooltip: "Может принимать значения: Не определён, Документ, Спецификация документа, Визуализация документа, Приложение, Подпись, Опись",
        },
        {
          width: 40,
          value: "mainIcon",
          text: "",
        },
        { width: 190, value: "title", text: "Имя файла", tooltip: "Имя файла" },
        { width: 90, value: "extension", text: "Расширение файла", tooltip: "Расширение файла" },
        { width: 90, value: "size", text: "Размер файла", tooltip: "Размер файла" },
        { width: 170, value: "attachedTo", text: "Связь с другим файлом", tooltip: "Связь с другим файлом" },
        { width: 120, value: "previewStatusTitle", text: "Статус предпросмотра", tooltip: "Статус предпросмотра" },
        { width: 90, value: "status", text: "Статус файла", tooltip: "Статус файла" },
        { width: 60, value: "additionalActions", text: "" },
      ];
    },
    resultData(): IResultData[] {
      return [...((this.files as IEadDocumentFiles[]) || [])]
        .sort((a) => {
          return !a.attachedTo ? -1 : 1;
        })
        .map((file: IEadDocumentFiles, index, arr) => ({
          type: file.type?.title || "",
          uploadFileGuid: file.uploadFileGuid,
          title: file.name,
          extension: file.format?.title || "",
          id: file.id,
          isSig: file.format?.title === "sig" && !!arr.find((element) => element.title === file.attachedTo && !element.attachedTo),
          size: getFileSize(+file.size),
          attachedTo: file.attachedTo ? `связан с файлом ${file.attachedTo}` : "",
          previewStatusCode: file.previewStatus?.code,
          previewStatusTitle: file.previewStatus?.title,
          pages: file.pages,
          deleted: !!file.deleted,
          status: file.deleted ? "Удалён" : "Активен",
        }));
    },
  },
  methods: {
    ...mapActions("eadView", ["searchInCard", "reloadPreview"]),
    async recreatePreview(file: IResultData) {
      if (!file.id) {
        return;
      }
      const isReloaded = await this.reloadPreview(file.id);
      if (isReloaded) {
        this.$emit("refresh");
      }
    },
    changeMenuItem() {
      const menuItemsArr = (this.menuItems as { value: string }[]).map((item) => item.value);
      if (menuItemsArr.map((value) => this.filters[value]).every((item) => !item)) {
        Vue.nextTick(() => {
          this.filters[menuItemsArr[0]] = true;
        });
      }
    },
    handleOpen(file: IResultData) {
      if (file.id && (file.previewStatusCode || file.pages?.length)) {
        this.$emit("openPreview", file.id);
      }
    },
    async refreshFilter() {
      if (!this.textFilter) {
        this.highlightFields = [];
        return;
      }
      try {
        this.isSearchLoading = true;
        this.highlightFields = (await this.searchInCard({ id: this.id, keyword: this.textFilter, filters: this.filters })) || [];
      } finally {
        this.isSearchLoading = false;
      }
    },
  },
});
