import { fields } from "@monorepo/utils/src/variables/projectsData/eadView/fields";

export const currentFilters = (): Record<string, unknown> => ({
  [fields.FILTER_TITLE]: "",
  [fields.FILTER_REG_NUMBER]: "",
  [fields.FILTER_CIPHER_DOC]: "",
  [fields.FILTER_CIPHER_CASE]: "",
  [fields.FILTER_INDEX]: "",
  [fields.FILTER_TITLE_CASE]: "",
  [fields.FILTER_OIK]: {},
  [fields.FILTER_FUND]: {},
  [fields.FILTER_CHIPBOARD]: {},
  [fields.FILTER_KEYWORD]: null,
  [fields.FILTER_DATE_RECEIVED_FROM]: "",
  [fields.FILTER_DATE_RECEIVED_TO]: "",
  [fields.FILTER_TIME_RECEIVED_FROM]: "",
  [fields.FILTER_TIME_RECEIVED_TO]: "",
  [fields.FILTER_SEARCH_MORPH]: false,
  [fields.FILTER_SEARCH_NGRAMED]: false,
  [fields.FILTER_SEARCH_SYNONYM]: false,
  [fields.FILTER_SEARCH_FREQUENCY]: false,
  [fields.FILTER_DOC_TYPE]: [],
});
