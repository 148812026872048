import { ActionContext, Module } from "vuex";
import { IRootState } from "@monorepo/inventory/src/store";
import { IAuthState } from "@monorepo/authorization/src/store/modules/auth";
import store from "@monorepo/app/src/store";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { checkExistLibrary, encodeQueryData, getFullPath, getQuery } from "@monorepo/utils/src/api/utils";
import {
  convertFiltersCustomToApi,
  convertSearchFiltersCustomToApi,
  convertTreeFiltersCustomToApi,
} from "@monorepo/inventory/src/views/EadView/utils/convertFiltersToApi";
import { IEadElement } from "@monorepo/inventory/src/views/EadView/types/eadElement";
import { getSelectedIds } from "@monorepo/utils/src/utils/getSelectedIds";
import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { convertFiltersToApi, IConvertFiltersToApiResponse } from "@monorepo/utils/src/api/convertFiltersToApi";
import { mutations as baseMutations } from "@monorepo/utils/src/store/modules/mutations";
import { actions as baseActions } from "@monorepo/utils/src/store/modules/actions";
import { cloneDeep } from "lodash";
import { getters as baseGetters } from "@monorepo/utils/src/store/modules/getters";
import { ISection, Sections } from "@monorepo/utils/src/types/cellsSections";
import { ISearchTemplate } from "@monorepo/utils/src/types/ISearchTemplate";
import { SORT_TYPE } from "@monorepo/utils/src/types/sortTypes";
import { sortTableOnlyDateWithNonDate } from "@monorepo/utils/src/utils/sortTableOnlyDateWithNonDate";
import { IAutocompleteElement } from "@monorepo/utils/src/types/oikAutocompleteType";
import axios from "axios";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { TreeItem } from "@monorepo/inventory/src/views/EadView/types/treeItem";
import { fields } from "@monorepo/utils/src/variables/projectsData/eadView/fields";
import { IDocChapter, IDocSection, IDocTypeElement } from "@monorepo/catalog/src/views/DocTypeView/types/docTypeElement";
import { DocumentTypes } from "@monorepo/utils/src/types/documentTypes";
import { ViewMode } from "@monorepo/utils/src/types/viewMode";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";

interface ITableState {
  filters: ITableFiltersObject;
  totalLength: number;
  section?: Sections;
  data: IEadElement[];
  infiniteId: string;
  isOpenFilters: boolean;
  autorefresh: boolean;
  treeData: TreeItem[];
  treeDataTotalLength: number;
  sectionCells: ISection;
  cells: string[];
  searchTemplates: ISearchTemplate[];
  libraries: {
    oiks: string[];
    statuses: string[];
    sections: IDocSection[];
    paperStatuses: Record<string, string>[];
  };
  isTableLoading: boolean;
  isLoadingToggleFilters: boolean;
  isLoadingChangeAutorefresh: boolean;
  settingElasticValue: boolean;
  viewMode: {
    id?: number;
    typeDisplay: ViewMode;
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const convertTreeFilters = (fieldFilters: Record<string, any>) => ({
  [fields.FILTER_OIK]: fieldFilters[fields.FILTER_OIK],
  [fields.FILTER_FUND]: fieldFilters[fields.FILTER_FUND],
  [fields.FILTER_CHIPBOARD]: fieldFilters[fields.FILTER_CHIPBOARD],
  eadStatus: fieldFilters[fields.FILTER_STATUS],
});

const defaultFilters = (): ITableFiltersObject => ({
  sort: {
    createDate: SORT_TYPE.DESC,
  },
  openedId: null,
  isSelectAll: false,
  selectedIds: {},
  fieldFilters: {
    [fields.FILTER_SEARCH_MORPH]: true,
  },
  initMessagesLength: 50,
});

const getDefaultState = (): ITableState => ({
  filters: defaultFilters(),
  infiniteId: new Date().toString(),
  totalLength: 0,
  treeData: [],
  treeDataTotalLength: 0,
  data: [],
  isOpenFilters: false,
  autorefresh: false,
  cells: [],
  searchTemplates: [],
  sectionCells: {} as ISection,
  section: Sections.EAD,
  libraries: {
    oiks: [],
    statuses: [],
    sections: [],
    paperStatuses: [],
  },
  isTableLoading: false,
  isLoadingToggleFilters: false,
  isLoadingChangeAutorefresh: false,
  settingElasticValue: false,
  viewMode: {
    typeDisplay: ViewMode.TABLE,
  },
});

const resultExportPath = (payload: "PDF" | "CSV" | "XLSX") => {
  switch (payload) {
    case "CSV":
      return QUERY_PATH.GET_EAD_CSV_LIST;
    case "XLSX":
      return QUERY_PATH.GET_EAD_XLS_LIST;
    default:
      return QUERY_PATH.GET_EAD_PDF_LIST;
  }
};

export const eadView: Module<ITableState, IRootState> = {
  namespaced: true,
  state: getDefaultState,
  mutations: {
    ...baseMutations,
    refreshData(state: ITableState) {
      state.data = [];
      state.treeData = [];
    },
    setTreeData(
      state: ITableState,
      payload: {
        data: TreeItem[];
        totalLength: number;
      }
    ) {
      state.treeData = [...state.treeData, ...payload.data];
      state.treeDataTotalLength = payload.totalLength;
    },
    addSort(state: ITableState, payload: Record<string, SORT_TYPE>) {
      state.filters.sort = sortTableOnlyDateWithNonDate(state.filters.sort, payload, []);
    },
    setFilters(state: ITableState, payload: ReturnType<typeof defaultFilters>) {
      state.filters = payload;
    },
    resetState(state: ITableState) {
      Object.assign(state, getDefaultState());
    },
    addStatusesLib(state: ITableState, payload: string[]) {
      state.libraries.statuses = payload || [];
    },
    addSectionLib(state: ITableState, payload: IDocSection[]) {
      state.libraries.sections =
        payload.map((item) => {
          return { ...item, label: item.name };
        }) || [];
    },
    addPaperStatusesLib(state: ITableState, payload: Record<string, string>[]) {
      state.libraries.paperStatuses = payload || [];
    },
    setElasticValue(state: ITableState, payload: boolean) {
      state.settingElasticValue = payload;
    },
    clearFilters(state: ITableState) {
      state.filters = defaultFilters();
    },
    addData(
      state: ITableState,
      payload: {
        data: IEadElement[];
        totalLength: number;
        isReset: boolean;
      }
    ) {
      state.data = payload.isReset ? payload.data : state.data.concat(payload.data);
      state.totalLength = payload.totalLength;
    },
    addOiksLib(state: ITableState, payload: string[]) {
      state.libraries.oiks = payload || [];
    },
    setTableLoading(state: ITableState, payload: boolean) {
      state.isTableLoading = payload;
    },
  },
  actions: {
    ...baseActions,
    async getSearchAutocomplete(info, params: string) {
      if (params?.length < 3) {
        return [];
      }

      const { data } = await getQuery<IAutocompleteElement[]>(
        QUERY_PATH.GET_EAD_SEARCH_AUTOCOMPLETE,
        {
          keyword: params,
        },
        false
      );
      if (data !== null) {
        const result = data
          .map((item: { suggestion: string }) => {
            const title = item.suggestion.replaceAll("@!!", "");
            return {
              titleValue: title,
              code: title,
            };
          })
          .filter((item: { titleValue: string }) => item.titleValue !== params);
        return [{ titleValue: params, code: params }].concat(result);
      }
      return [];
    },
    async getAutocompleteFilterByCode(info, params: string) {
      const { data } = await getQuery<IAutocompleteElement[]>(
        QUERY_PATH.GET_FUND_AUTOCOMPLETE_NUMBER_ITEMS,
        {
          keyword: params,
        },
        false
      );
      if (data !== null) {
        return data.map((item: IAutocompleteElement) => ({
          ...item,
          titleValue: item.isSearchCode ? item.code : item.isSearchNumber ? item.number : item.name,
        }));
      }
      return [];
    },
    async getTreeData(info) {
      const treeFilters = convertTreeFilters(info.state.filters.fieldFilters);
      const params = convertFiltersToApi(
        { ...info.state.filters, fieldFilters: treeFilters, initMessagesLength: 50 },
        info.state.treeData.length,
        convertTreeFiltersCustomToApi,
        false,
        true
      );
      const { data, total: totalLength } = await getQuery<TreeItem[]>(QUERY_PATH.GET_FUND_EAD_TREE_LIST, params).finally(() => {
        info.commit("setTableLoading", false);
      });
      if (data !== null) {
        info.commit("setTreeData", { data: data || [], totalLength: totalLength || 0 });
        return { data: info.state.treeData || [], totalLength: totalLength || 0 };
      }
      return { data: null, error: "error" };
    },
    async getInventoryTreeData(info, { length, fundCode }) {
      const treeFilters = {
        [fields.FILTER_CHIPBOARD]: info.state.filters.fieldFilters[fields.FILTER_CHIPBOARD],
        [fields.EAD_STATUS]: info.state.filters.fieldFilters[fields.FILTER_STATUS],
      };

      const params = convertFiltersToApi(
        { ...info.state.filters, fieldFilters: treeFilters, initMessagesLength: 50 },
        length,
        convertTreeFiltersCustomToApi,
        false,
        true
      );

      const { data, total: totalLength } = await getQuery<TreeItem[]>(`${QUERY_PATH.GET_INV_TREE_LIST}/${fundCode}`, params).finally(() => {
        info.commit("setTableLoading", false);
      });

      if (data !== null) {
        return { data: data || [], totalLength: totalLength || 0 };
      }

      return { data: [], totalLength: 0 };
    },
    async getEadTreeData(info, { length, id }) {
      const treeFilters = {
        [fields.FILTER_CHIPBOARD]: info.state.filters.fieldFilters[fields.FILTER_CHIPBOARD],
        [fields.EAD_STATUS]: info.state.filters.fieldFilters[fields.FILTER_STATUS],
      };

      const params = convertFiltersToApi(
        { ...info.state.filters, fieldFilters: treeFilters, initMessagesLength: 50 },
        length,
        convertTreeFiltersCustomToApi,
        false,
        true
      );

      const { data, total: totalLength } = await getQuery<TreeItem[]>(`${QUERY_PATH.GET_EAD_BY_CASE_TREE_LIST}/${id}`, params).finally(() => {
        info.commit("setTableLoading", false);
      });

      if (data !== null) {
        return { data: data || [], totalLength: totalLength || 0 };
      }

      return { data: [], totalLength: 0 };
    },
    async getCaseTreeData(info, { length, id }) {
      const treeFilters = {
        [fields.FILTER_CHIPBOARD]: info.state.filters.fieldFilters[fields.FILTER_CHIPBOARD],
        [fields.EAD_STATUS]: info.state.filters.fieldFilters[fields.FILTER_STATUS],
      };

      const params = convertFiltersToApi(
        { ...info.state.filters, fieldFilters: treeFilters, initMessagesLength: 50 },
        length,
        convertTreeFiltersCustomToApi,
        false,
        true
      );

      const { data, total: totalLength } = await getQuery<TreeItem[]>(`${QUERY_PATH.GET_CASE_TREE_LIST}/${id}`, params).finally(() => {
        info.commit("setTableLoading", false);
      });

      if (data !== null) {
        return { data: data || [], totalLength: totalLength || 0 };
      }

      return { data: [], totalLength: 0 };
    },
    addOpenedId(root, payload: null | string | number) {
      (baseActions as { addOpenedId: (root: ActionContext<ITableState, IRootState>, payload: null | string | number) => void }).addOpenedId(
        root,
        payload
      );
      // if (payload) {
      //   root.commit(
      //     "auth/setUserEadHistory",
      //     {
      //       id: uuid(),
      //       href: location.pathname,
      //       filters: root.state.filters,
      //       section: root.state.section,
      //       item: (root.state.data ?? []).find((item: IEadElement) => item.id === payload) || { id: payload },
      //     },
      //     { root: true }
      //   );
      // }
    },
    async getAutocompleteByNumber(info, params: string) {
      const { data } = await getQuery<IAutocompleteElement[]>(QUERY_PATH.GET_CATALOG_FUND_LIST + `/by-number/like`, { keyword: params }, false);
      if (data !== null) {
        return data.map((item: { number: string }) => ({
          ...item,
          titleValue: item.number,
        }));
      }
      return [];
    },
    async getAutocompleteByName(info, params: string) {
      const { data } = await getQuery<IAutocompleteElement[]>(
        QUERY_PATH.GET_ARCHIVE_AUTOCOMPLETE_ITEMS,
        {
          keyword: params,
        },
        false
      );
      if (data !== null) {
        return data.map((item: IAutocompleteElement) => ({
          ...item,
          titleValue: item.isSearchCode ? item.code : item.name,
        }));
      }
      return [];
    },
    async getStatuses({ commit, state }) {
      checkExistLibrary(state.libraries.statuses, async () => {
        const { data } = await getQuery<string[]>(QUERY_PATH.GET_EAD_STATUSES_LIB);
        commit("addStatusesLib", data);
      });
    },
    async getOiks({ commit, state }) {
      checkExistLibrary(state.libraries.oiks, async () => {
        const { data } = await getQuery<string[]>(QUERY_PATH.GET_OIKS_LIB);
        commit("addOiksLib", data);
      });
    },
    async getPaperStatuses({ commit, state }) {
      checkExistLibrary(state.libraries.paperStatuses, async () => {
        const { data } = await getQuery<string[]>(QUERY_PATH.SAVE_PAPER_STATUS);
        commit("addPaperStatusesLib", data);
      });
    },
    async getSectionList({ commit }) {
      const { data } = await getQuery<IDocChapter[]>(
        `${QUERY_PATH.GET_CATALOG_DOC_TYPE_SECTIONS}/${DocumentTypes.MANAGEMENT_DOCUMENTS}`,
        {},
        true,
        "",
        "data"
      );
      if (data !== null) {
        commit("addSectionLib", data);
      }
      return [];
    },
    async getElasticValue({ commit }) {
      const { data } = await getQuery<string[]>(QUERY_PATH.GET_ELASTIC_SETTING);
      commit("setElasticValue", data === "true");
    },
    async searchInCard(info, { id, keyword, filters }) {
      try {
        const params = convertFiltersToApi(
          { fieldFilters: filters, sort: {}, initMessagesLength: 0, openedId: null },
          0,
          convertSearchFiltersCustomToApi,
          true,
          true
        );
        const result = await getQuery<any>(
          getFullPath(QUERY_PATH.SEARCH_IN_CARD, `/${id}`),
          {
            ...params,
            keyword,
          },
          true,
          "Семантический поиск временно не доступен."
        ).catch(function (error) {
          showNotification("Ошибка добавления в поиск");
          return Promise.reject(error);
        });
        if (!result.data && result.code === 0) {
          showNotification("По вашему запросу ничего не найдено. Скорректируйте поиск", NOTIFICATION_STATUS.INFO);
        }
        return result.data?.highlightEadFields || {};
      } catch (e) {
        return {};
      }
    },
    async getEventList({ commit, state, rootGetters }) {
      if (rootGetters["auth/isShowAnimation"]) {
        commit("setTableLoading", true);
      }
      const filters = cloneDeep(state.filters);
      const keyword = filters.fieldFilters.keyword as unknown as { code: string };
      delete filters.fieldFilters.keyword;
      const params = convertFiltersToApi(filters, state.data.length, keyword ? convertSearchFiltersCustomToApi : convertFiltersCustomToApi);
      const {
        data,
        total: totalLength,
        error,
      } = await getQuery<IEadElement[]>(
        keyword ? QUERY_PATH.GET_EAD_TMP_LIST : QUERY_PATH.GET_EAD_LIST,
        {
          ...params,
          ...(keyword ? { keyword: keyword?.code } : {}),
        },
        true,
        keyword ? "Семантический поиск временно не доступен." : undefined
      ).finally(() => {
        commit("setTableLoading", false);
      });
      if (data !== null) {
        commit("addData", { data: data || [], totalLength: totalLength || 0 });
        return { data: state.data || [], totalLength: state.totalLength || 0 };
      }
      return { data: null, error };
    },
    async getEadElement(info, payload: string) {
      const { data } = await getQuery<IEadElement>(`${QUERY_PATH.GET_EAD_LIST}/${payload}`);
      return data || {};
    },
    async getDocKindVersion(info, id: string) {
      const { data } = await getQuery<IDocTypeElement>(`${QUERY_PATH.GET_CATALOG_DOC_TYPE_LIST}/by-id/${id}`);
      return data?.section?.versionId || "";
    },
    async saveCard(info, data: Record<string, string>): Promise<boolean> {
      try {
        const { entityId, comment } = data;
        await axios.put(getFullPath(QUERY_PATH.SAVE_EAD_COMMENT), { entityId, comment }).catch(function (error) {
          showNotification(error.response.data.message);
          return Promise.reject(error);
        });
        return true;
      } catch (e) {
        return false;
      }
    },
    async addToElastic(info, id: string): Promise<boolean> {
      try {
        await axios.post(getFullPath(QUERY_PATH.ADD_TO_ELASTIC, `/${id}`)).catch(function (error) {
          showNotification("Ошибка добавления в поиск");
          return Promise.reject(error);
        });
        return true;
      } catch (e) {
        return false;
      }
    },
    async reloadPreview(info, id: string): Promise<boolean> {
      try {
        await axios.post(`${QUERY_PATH.RELOAD_PREVIEW_EAD}/${id}`).catch(function (error) {
          showNotification("Ошибка пересоздания изображений предпросмотра");
          return Promise.reject(error);
        });
        return true;
      } catch (e) {
        return false;
      }
    },
    async refreshEventList({ commit, state }) {
      const filters = cloneDeep(state.filters);
      const keyword = filters.fieldFilters.keyword as unknown as { code: string };
      delete filters.fieldFilters.keyword;
      const params = convertFiltersToApi(filters, state.data.length, keyword ? convertSearchFiltersCustomToApi : convertFiltersCustomToApi);
      const { data, total: totalLength } = await getQuery<IEadElement[]>(
        keyword ? QUERY_PATH.GET_EAD_TMP_LIST : QUERY_PATH.GET_EAD_LIST,
        {
          ...params,
          ...(keyword ? { keyword: keyword?.code } : {}),
          offset: 0,
        },
        true,
        keyword ? "Семантический поиск временно не доступен." : undefined
      );

      if (data !== null) {
        commit("addData", { data: data || [], totalLength: totalLength || 0, isReset: true });
      }
      return { data: null };
    },
    async getTxtFile(info, { uuid, offset, limit }: { uuid: string; offset: number; limit: number }) {
      const token = (store?.state?.auth as IAuthState)?.user?.details?.token;
      return fetch(`${getFullPath(QUERY_PATH.GET_TXT_FILE_FROM_FILE_STORAGE)}?uuid=${uuid}&offset=${offset}&limit=${limit}&origin=true`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => response.text());
    },
    async getExportData({ state, dispatch, commit }, payload: "CSV" | "PDF" | "XLSX") {
      let params: IConvertFiltersToApiResponse | { ids: string[] };
      const selectedKeys = state.filters.selectedIds ? getSelectedIds(state.filters.selectedIds) : [];
      if (selectedKeys.length && !state.filters.isSelectAll) {
        params = {
          ids: selectedKeys,
        };
      } else {
        params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi, true);
      }
      const queryParams = encodeQueryData(params);
      if (payload !== "PDF") {
        dispatch("app/openNewWindow", getFullPath(resultExportPath(payload), queryParams), {
          root: true,
        });
      } else {
        commit("app/setIsExportFileLoading", true, {
          root: true,
        });
        const { data } = await getQuery<void>(QUERY_PATH.GET_EAD_PDF_LIST_ASYNC, params);
        if (!data) {
          commit("app/setIsExportFileLoading", false, {
            root: true,
          });
        }
      }
    },
  },
  getters: {
    ...baseGetters,
    oiksLibrary(state: ITableState) {
      return state.libraries.oiks;
    },
    treeData(state: ITableState) {
      return state.treeData || [];
    },
    isTableLoading(state: ITableState) {
      return state.isTableLoading;
    },
    sectionsList(state: ITableState) {
      return state.libraries.sections || [];
    },
    elasticValue(state: ITableState) {
      return state.settingElasticValue;
    },
    paperStatuses(state: ITableState) {
      return state.libraries.paperStatuses;
    },
  },
};
