var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.elasticValue)?_c('div',{staticClass:"ml-2 mb-4 mr-3 d-flex"},[_c('div',{staticClass:"mr-2 filters__autocomplete-wrap filter__search"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":11,"nudge-top":-10,"content-class":"export-btn__content","transition":"scale-transition","left":"","offset-y":"","min-width":"auto"},nativeOn:{"click":function($event){$event.stopPropagation();}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"filters__menu-icon",nativeOn:{"click":function($event){$event.stopPropagation();}}},'v-icon',attrs,false),on),[_vm._v("mdi-cog")])]}}],null,false,3421495032),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('div',{staticClass:"pa-4 filters__menu-wrapper"},_vm._l((_vm.menuItems),function(item){return _c('div',{key:item.value,staticClass:"d-flex flex-row align-center py-1"},[_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"ripple":false,"hide-details":""},on:{"change":_vm.changeMenuItem},model:{value:(_vm.filters[item.value]),callback:function ($$v) {_vm.$set(_vm.filters, item.value, $$v)},expression:"filters[item.value]"}}),_c('div',[_vm._v(_vm._s(item.title))])],1)}),0)]),_c('TextFilter',{attrs:{"title":"Поиск по документам"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.refreshFilter($event)}},model:{value:(_vm.textFilter),callback:function ($$v) {_vm.textFilter=$$v},expression:"textFilter"}})],1),_c('v-btn',{class:['filters__button filters__save-button filters__save-button_fix-radius'],attrs:{"id":"sendData","data-test-id":"sendData","color":"#2462D1","loading":_vm.isSearchLoading},on:{"click":_vm.refreshFilter}},[_vm._v(" Найти ")])],1):_vm._e(),_c('v-simple-table',{staticClass:"ml-2 mr-2 files-table files-table_sticky",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',_vm._l((_vm.headers),function(header){return _c('th',{key:header.value,class:['files-table__th pl-3 pr-3 pt-2 pb-2', { handle: header.value !== 'checkbox' }],style:({ width: header.width ? ((header.width) + "px") : '' })},[_c('v-tooltip',{attrs:{"fixed":"","z-index":"200","top":"","disabled":!header.tooltip},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('div',[_vm._v(_vm._s(header.tooltip))])])],1)}),0)]),_c('tbody',[_vm._l((_vm.resultData),function(event){return [_c('tr',{key:event.title},_vm._l((_vm.headers),function(header){return _c('td',{key:("" + (event.title) + (header.value)),staticClass:"files-table__td pl-3 pr-3 pt-2 pb-2",style:({ width: header.width ? ((header.width) + "px") : '' }),attrs:{"data-test-id":"tableCell"}},[(header.value === 'mainIcon' && !event.attachedTo)?_c('div',{staticClass:"files-table__icon"},[_c('v-img',{attrs:{"height":"48","width":"48","contain":"","src":_vm.iconSrc}})],1):_vm._e(),(header.value === 'mainIcon' && event.isSig)?_c('div',{staticClass:"files-table__icon"},[_c('v-icon',{attrs:{"color":"#000"}},[_vm._v("mdi-file-certificate-outline")])],1):_vm._e(),(header.value === 'additionalActions')?_c('div',{staticClass:"files-table__icon"},[(_vm.isShowRefreshPreview(event))?_c('v-tooltip',{attrs:{"fixed":"","z-index":"200","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.recreatePreview(event)}}},on),[_c('v-icon',{attrs:{"size":"20","color":"primary"}},[_vm._v("mdi-refresh")])],1)]}}],null,true)},[_c('div',[_vm._v("Пересоздать изображения предпросмотра")])]):_vm._e()],1):_vm._e(),(header.value === 'title')?_c('div',{class:{ 'files-table__link': event.previewStatusCode || (event.pages && event.pages.length) },style:({ overflow: 'hidden' }),on:{"click":function($event){return _vm.handleOpen(event)}}},[_vm._v(" "+_vm._s(event[header.value])+" ")]):_c('span',[_vm._v(_vm._s(event[header.value]))])])}),0),(_vm.highlightFields[event.uploadFileGuid])?_c('tr',{key:event.title + 'higlights'},[_c('td',{attrs:{"colspan":_vm.headers.length + 1}},[_c('table-search-results',{attrs:{"event":{ highlights: _vm.highlightFields[event.uploadFileGuid] }}})],1)]):_vm._e()]})],2)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }