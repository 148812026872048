




































































































































import { defineComponent, ref } from "@vue/composition-api";
import TestImgPreview from "@monorepo/inventory/src/components/TestImgPreview.vue";
import TestImgPreviewMock from "./TestImgPreviewMock.vue";
import TxtViewer from "@monorepo/inventory/src/components/TxtViewer.vue";
import EadInfoElement from "@monorepo/uikit/src/components/tableCardModal/CardModalInfoElement.vue";
import EadInventoryTable from "./EadInventoryTable.vue";
import DigitalSignaturesTable from "./DigitalSignaturesTable.vue";
import EadFilesTable from "./EadFilesTable.vue";
import CardModalStatusChip from "@monorepo/uikit/src/components/tableCardModal/CardModalStatusChip.vue";
import { mapActions, mapGetters } from "vuex";
import CardModalToggleViewSize from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleViewSize.vue";
import CardModalToggleTableItems from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleTableItems.vue";
import { EadStatuses } from "@monorepo/inventory/src/views/EadView/types/eadStatuses";
import { CardMode } from "@monorepo/utils/src/types/cardMode";
import { IEadElement } from "@monorepo/inventory/src/views/EadView/types/eadElement";
import { getPluralNoun } from "@monorepo/utils/src/utils/pluralNoun";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { modalElements, mrpElements, techElements, paperCaseElements } from "@monorepo/utils/src/variables/projectsData/eadView/modalElements";
import { convertApiItemToUi } from "@monorepo/inventory/src/views/EadView/utils/convertApiItemToUi";
import { get } from "lodash";
import { viewUniqKey } from "@monorepo/utils/src/variables/projectsData/eadView/viewTitle";
import { fields } from "@monorepo/utils/src/variables/projectsData/eadView/fields";
import { getFullPath } from "@monorepo/utils/src/api/utils";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { Sections } from "@monorepo/utils/src/types/cellsSections";
import { Route } from "vue-router";
import ExportBtn from "@monorepo/uikit/src/components/tableViews/ExportBtn.vue";
import FormInputElement from "@monorepo/uikit/src/components/tableCardModal/FormInputElement.vue";
import { ModalType } from "@monorepo/utils/src/variables/modalType";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";
import CardModalInfoElement from "@monorepo/uikit/src/components/tableCardModal/CardModalInfoElement.vue";
import CardModalChapter from "@monorepo/uikit/src/components/tableCardModal/CardModalChapter.vue";
import useCheckChangesModal from "@monorepo/utils/src/composables/useCheckChangesModal";
import useModalChangeByKeypress from "@monorepo/utils/src/composables/useModalChangeByKeypress";
import useUniqueLinkModal from "@monorepo/utils/src/composables/useUniqueLinkModal";
import { convertSaveCardObject } from "@monorepo/inventory/src/views/EadView/utils/convertSaveCardObject";
import useFormLinkByAuthoritiesModal from "@monorepo/utils/src/composables/useFormLinkByAuthoritiesModal";
import DocChangesHistory from "@monorepo/uikit/src/components/tableCardModal/DocChangesHistory.vue";
import { CaseTypes } from "@monorepo/inventory/src/constants/caseTypes";

export default defineComponent({
  name: "EadInfoModal",
  components: {
    TestImgPreview,
    TestImgPreviewMock,
    EadInfoElement,
    ExportBtn,
    EadInventoryTable,
    DigitalSignaturesTable,
    EadFilesTable,
    CardModalToggleViewSize,
    CardModalToggleTableItems,
    CardModalStatusChip,
    FormInputElement,
    CardModalInfoElement,
    TxtViewer,
    CardModalChapter,
    DocChangesHistory,
  },
  props: {
    type: {
      type: String,
      default: ModalType.EDIT,
    },
  },
  data() {
    return {
      ModalType,
      isLoadingPdf: false,
      isSaveLoading: false,
      EadStatuses,
      element: {} as ReturnType<typeof convertApiItemToUi>,
      isShowPdf: false,
      modalElements,
      techElements,
      mrpElements,
      paperCaseElements,
      viewUniqKey,
      cardMode: CardMode,
      section: Sections.EAD,
      openedPanels: [0, 1, 2, 3],
      fields,
      isLoading: false,
      fileId: null as number | null | string,
      versionId: "",
    };
  },
  watch: {
    openedId: {
      immediate: true,
      async handler(
        this: {
          $route: Route;
          addQueryOpenedId: () => void;
          getEadElement: (val: string) => Promise<IEadElement>;
          element: ReturnType<typeof convertApiItemToUi>;
          isLoading: boolean;
          isShowAnimation: boolean;
          formValues: Record<string, string>;
          changedFormValues: Record<string, string>;
          fileId: string | number | null;
          isShowPdf: boolean;
          versionId: string;
          getDocKindVersion: (val: string) => Promise<string>;
        },
        value
      ) {
        if (value) {
          this.isLoading = true;
          await this.addQueryOpenedId();
          await this.getEadElement(value)
            .then(async (data: IEadElement) => {
              this.element = convertApiItemToUi(data || {});
              this.formValues = { comment: this.element.comment || "" };
              this.changedFormValues = { ...this.formValues };
              if (data?.documentTypeId) {
                this.versionId = await this.getDocKindVersion(data.documentTypeId);
              }
            })
            .catch(console.error)
            .finally(() => {
              this.isLoading = false;
            });
        }
        if (this.$route.query?.previewFileId) {
          const queryFile = (this.element as unknown as any)?.[fields.DOCUMENT_FILES]?.find(
            (item: any) => item.id?.toString() === this.$route.query?.previewFileId
          );
          if (queryFile && (queryFile.previewStatus?.code || queryFile.pages?.length)) {
            this.fileId = Number(this.$route.query.previewFileId);
            this.isShowPdf = true;
          } else {
            this.isShowPdf = false;
          }
        }
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["user", "isShowAnimation", "cardModeList"]),
    ...mapGetters("eadView", ["data", "openedId"]),
    isShowPreview(): boolean {
      const files = (this.element as unknown as any)?.[fields.DOCUMENT_FILES] || [];
      return files.length && files.some((file: any) => file.previewStatus?.code || file.pages?.length);
    },
    isCaseHasPaper(): boolean {
      return this.element.caseDto?.storageKind?.title !== CaseTypes.ELECTRONIC;
    },
    file(): { format?: string } {
      const file = (this.element as unknown as any)?.[fields.DOCUMENT_FILES]?.find((item: any) => item.id === this.fileId);
      return file || {};
    },
    isEditQueuePrestine(): boolean {
      return (this.formValues as { comment: string }).comment === (this.changedFormValues as { comment: string }).comment;
    },
    cardModeResult(): CardMode {
      return this.cardModeList[this.section as string] || CardMode.DEFAULT;
    },
    isShowExport(): boolean {
      return isAuthorityExist(this.user, authorities.EAD_EXPORT);
    },
    isShowEpSection(): boolean {
      return !!this.element?.digitalSignatures?.length;
    },
    isShowTechData(): boolean {
      return isAuthorityExist(this.user, authorities.TECH_INFO);
    },
    isShowFilesSection(): boolean {
      return !!this.element?.files?.length;
    },
    getItemElement(): (key: string) => string {
      return (key: string): string => {
        switch (key) {
          default:
            return get(this.element, key) as string;
        }
      };
    },
    storagePeriod(): string {
      return `${this.element?.storagePeriod} ${getPluralNoun(this.element?.storagePeriod || 0, "год", "года", "лет")}`;
    },
    eadBaseFileExt(): string {
      return this.element?.mainMetaData?.type || "-";
    },
    eadBaseShowFileExt(): string {
      const applicationArr = (this.element?.mainMetaData?.type ?? "").split("/");
      return applicationArr.length > 1 ? applicationArr[1] : applicationArr[0] || "-";
    },
    status(): string {
      return this.element?.status?.toLowerCase() || "";
    },
    statusColor(): string {
      switch (this.statusResult) {
        case EadStatuses.RECEIVED:
          return "#00A459";
        case EadStatuses.NOT_RECEIVED:
          return "#D34039";
        case EadStatuses.DELETED:
          return "#D34039";
        default:
          return "";
      }
    },
    statusResult(): EadStatuses | null {
      if (!this.status) {
        return null;
      }

      switch (this.status) {
        case "получен":
          return EadStatuses.RECEIVED;
        case "не получен":
          return EadStatuses.NOT_RECEIVED;
        case "удалён":
          return EadStatuses.DELETED;
        default:
          return null;
      }
    },
    togglePdfTitle(): string {
      return this.isShowPdf ? "Закрыть предпросмотр" : "Открыть предпросмотр";
    },
    togglePdfIcon(): string {
      return this.isShowPdf ? "mdi-arrow-right" : "mdi-arrow-left";
    },
    historyList(): any[] {
      return this.element.caseDto?.updateHistoryList || [];
    },
  },
  methods: {
    ...mapActions("app", ["openNewWindow"]),
    ...mapActions("eadView", ["changeOpenedId", "getEadElement", "saveCard", "addToElastic", "getScreenshot", "getTxtFile", "getDocKindVersion"]),
    async refreshData() {
      this.isShowPdf = false;
      this.isLoading = true;
      await this.getEadElement(this.openedId)
        .then(async (data: IEadElement) => {
          this.element = convertApiItemToUi(data || {});
          this.formValues = { comment: this.element.comment || "" };
          this.changedFormValues = { ...this.formValues };
          if (data?.documentTypeId) {
            this.versionId = await this.getDocKindVersion(data.documentTypeId);
          }
        })
        .catch(console.error)
        .finally(() => {
          this.isLoading = false;
        });
    },
    downloadMrd(id: string) {
      this.openNewWindow(`${getFullPath(QUERY_PATH.GET_FILE_FROM_FILE_STORAGE)}?uuid=${id}`);
    },
    openPreview(fileId: number) {
      this.fileId = fileId;
      this.isShowPdf = true;
    },
    openCardPdf(id: string) {
      if (id) {
        this.openNewWindow(`${getFullPath(QUERY_PATH.GET_EAD_CARD_PDF)}/${id}/pdf`, "_blank");
      }
    },
    clickElementCb(header: { isLink: boolean; value: string }) {
      switch (header.value) {
        case fields.EAD_CASE_INDEX:
        case fields.CASE_NUMBER: {
          (
            this as unknown as {
              moveByAuthorities: (header: { isLink: boolean }, path: string, query: Record<string, unknown>) => void;
            }
          ).moveByAuthorities(header, "/inventory/case", {
            index: this.element.caseIndex as string,
            isOpenModal: "1",
          });
          break;
        }
        case fields.EAD_CASE_TITLE: {
          (
            this as unknown as {
              moveByAuthorities: (header: { isLink: boolean }, path: string, query: Record<string, unknown>) => void;
            }
          ).moveByAuthorities(header, "/inventory/case", {
            header: this.element.caseTitle as string,
            isOpenModal: "1",
          });
          break;
        }
        case fields.AK_ID: {
          (
            this as unknown as {
              moveByAuthorities: (header: { isLink: boolean }, path: string, query: Record<string, unknown>) => void;
            }
          ).moveByAuthorities(header, "/inventory/ak", {
            id: this.element.akId,
            isOpenModal: "1",
          });
          break;
        }
        case fields.INVENTORY_NUMBER: {
          (
            this as unknown as {
              moveByAuthorities: (header: { isLink: boolean }, path: string, query: Record<string, unknown>) => void;
            }
          ).moveByAuthorities(header, "/inventory/case-notes-list", {
            inventoryNumber: this.element.inventoryNumber as string,
            isOpenModal: "1",
          });
          break;
        }
        case fields.INVENTORY_YEAR: {
          (
            this as unknown as {
              moveByAuthorities: (header: { isLink: boolean }, path: string, query: Record<string, unknown>) => void;
            }
          ).moveByAuthorities(header, "/inventory/case-notes-list", {
            inventoryNumber: this.element.inventoryNumber as string,
            isOpenModal: "1",
          });
          break;
        }
        case fields.OIK_SHORT_NAME: {
          (
            this as unknown as {
              moveByAuthorities: (header: { isLink: boolean }, path: string, query: Record<string, unknown>) => void;
            }
          ).moveByAuthorities(header, "/dictionaries/oik", {
            name: this.element.oikShortName as string,
            isOpenModal: "1",
          });
          break;
        }

        case fields.DOC_TYPE_ID: {
          console.log(this.versionId);
          this.moveByAuthorities(header, "/dictionaries/doc-type", {
            id: this.element.documentTypeId as string,
            isOpenModal: "1",
            ...(this.versionId ? { versionId: this.versionId } : {}),
          });
          break;
        }

        case fields.FUND_NUMBER: {
          (
            this as unknown as {
              moveByAuthorities: (header: { isLink: boolean }, path: string, query: Record<string, unknown>) => void;
            }
          ).moveByAuthorities(header, "/dictionaries/fund", {
            number: this.element.fundNumber as string,
            isOpenModal: "1",
          });
          break;
        }

        case fields.ARCHIVE_NAME: {
          (
            this as unknown as {
              moveByAuthorities: (header: { isLink: boolean }, path: string, query: Record<string, unknown>) => void;
            }
          ).moveByAuthorities(header, "/dictionaries/archive", {
            id: this.element.archive?.id,
            isOpenModal: "1",
          });
          break;
        }

        default:
          break;
      }
    },
    closeModal() {
      if (this.type !== ModalType.READ) {
        const isClose = (this as unknown as { checkChangesBeforeClose: () => boolean }).checkChangesBeforeClose();
        if (isClose) {
          return void this.$emit("close");
        }
        return;
      }
      this.$emit("close");
    },
    togglePdf() {
      this.isShowPdf = !this.isShowPdf;

      if (this.isShowPdf) {
        const mainId = (this.element?.files || []).find((file: any) => !file.attachedTo)?.id || null;
        const firstValidId = (this.element?.files || []).find((file: any) => file.previewStatus?.code || file.pages?.length)?.id || null;
        this.fileId = mainId || firstValidId;

        if (!this.fileId) {
          this.isShowPdf = false;
          showNotification("Отсутствуют файлы для просмотра.");
        }
      }
    },
    downloadPdf() {
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(true, "ModalUnderConstruction"));
    },
    getResultSaveData(data: Record<string, string>) {
      return {
        comment: data.comment || "",
      };
    },
    async onSave(data: Record<string, string>) {
      const isNeedSave = (this as unknown as { checkChangesBeforeSave: () => boolean }).checkChangesBeforeSave();
      if (isNeedSave) {
        const resultData = { ...this.getResultSaveData(data), entityId: this.openedId };

        this.isSaveLoading = true;
        const isSaved = await this.saveCard(resultData).finally(() => {
          this.isSaveLoading = false;
        });
        if (isSaved) {
          this.changedFormValues = this.getResultSaveData(resultData);
          showNotification("Данные успешно сохранены.", NOTIFICATION_STATUS.SUCCESS);
          this.closeModal();
        }
      } else {
        return;
      }
    },
    clickOik(path: string, query: Record<string, string>) {
      this.$router.push({ path, query: { ...query } });
    },
  },
  setup(props, context) {
    const { root } = context;
    const formValues = ref({});
    const changedFormValues = ref({});
    const { checkChangesBeforeClose, checkChangesBeforeSave } = useCheckChangesModal(context, formValues, changedFormValues, convertSaveCardObject);
    useModalChangeByKeypress(root, "eadView", Sections.EAD, props.type);
    const { addQueryOpenedId } = useUniqueLinkModal(root, "eadView");
    const { moveByAuthorities } = useFormLinkByAuthoritiesModal(root, modalElements);

    return {
      formValues,
      changedFormValues,
      addQueryOpenedId,
      checkChangesBeforeClose,
      checkChangesBeforeSave,
      moveByAuthorities,
    };
  },
});
