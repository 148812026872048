import { v4 as uuid } from "uuid";
import { fields } from "@monorepo/utils/src/variables/projectsData/eadView/fields";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { standardMask } from "@monorepo/utils/src/directives/standardMask";
import { cloneDeep } from "lodash";

export const modalElements = [
  {
    id: uuid(),
    isEdited: false,
    title: "№ документа",
    tooltip: "Регистрационный номер документа в СЭД",
    className: "info-modal__element_title",
    value: fields.EAD_NUMBER,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата регистрации в СЭД",
    tooltip: "Дата регистрации в СЭД",
    className: "info-modal__element_title",
    value: fields.REGISTRATION_DATE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Заголовок",
    tooltip: "Заголовок документа",
    isShowContentTooltip: true,
    className: "info-modal__element_title",
    value: fields.EAD_TITLE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Архивный шифр",
    tooltip: "Архивный шифр документа",
    className: "info-modal__element_title",
    value: fields.EAD_ARCHIVE_CIPHER,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Архивный шифр дела",
    tooltip: "Архивный шифр дела, с которым связан ЭАД",
    className: "info-modal__element_title",
    value: fields.EAD_CASE_ARCHIVE_CIPHER,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Заголовок дела",
    tooltip: "Заголовок дела, с которым связан ЭАД",
    className: "info-modal__element_title",
    value: fields.EAD_CASE_TITLE,
    linkRights: [authorities.CASE_LIST],
    isLink: true,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Индекс дела",
    tooltip: "Индекс дела, с которым связан ЭАД",
    className: "info-modal__element",
    value: fields.EAD_CASE_INDEX,
    linkRights: [authorities.CASE_LIST],
    isLink: true,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "№ описи дел",
    tooltip: "Номер описи дел в ОИК",
    className: "info-modal__element",
    value: fields.INVENTORY_NUMBER,
    linkRights: [authorities.INVENTORY_LIST],
    isLink: true,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Год описи",
    tooltip: "Год, за который сформирована опись дел",
    className: "info-modal__element",
    value: fields.INVENTORY_YEAR,
    linkRights: [authorities.INVENTORY_LIST],
    isLink: true,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "ОИК",
    tooltip: "Краткое наименование ОИК",
    className: "info-modal__element",
    value: fields.OIK_SHORT_NAME,
    linkRights: [authorities.OIK_LIST],
    isLink: true,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Фонд",
    tooltip: "Номер фонда",
    className: "info-modal__element",
    value: fields.FUND_NUMBER,
    linkRights: [authorities.FUND_LIST],
    isLink: true,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата поступления",
    tooltip: "Дата поступления в ЦХЭД",
    className: "info-modal__element",
    value: fields.RECEIVE_DATE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Вид носителя",
    tooltip: "Вид носителя может принимать значения: Бумажный, Электронный",
    className: "info-modal__element",
    value: fields.EAD_TYPE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Подлинность документа",
    tooltip: "Подлинность документа может принимать значения: Подлинник, Копия",
    className: "info-modal__element",
    value: fields.EAD_AUTHENTICITY,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "ДСП",
    tooltip: "Признак документа для служебного пользования",
    className: "info-modal__element",
    value: fields.EAD_RESTRICTED,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Идентификатор АК",
    tooltip: "Идентификатор архивного контейнера",
    className: "info-modal__element",
    value: fields.AK_ID,
    linkRights: [authorities.AK_LIST],
    isLink: true,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Идентификатор вида документа",
    tooltip: "Идентификатор вида документа",
    className: "info-modal__element",
    value: fields.DOC_TYPE_ID,
    linkRights: [authorities.DOC_KIND_LIST],
    isLink: true,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Архив",
    tooltip: "Архив",
    className: "info-modal__element_title",
    value: fields.ARCHIVE_NAME,
    linkRights: [authorities.ARCHIVE_LIST],
    isLink: true,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Адрес архива",
    tooltip: "Адрес архива",
    className: "info-modal__element_title",
    value: fields.ARCHIVE_ADDRESS,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "№ фонда",
    tooltip: "Номер фонда",
    className: "info-modal__element",
    value: fields.FUND_NUMBER,
    linkRights: [authorities.FUND_LIST],
    isLink: true,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Название фонда",
    tooltip: "Название фонда",
    className: "info-modal__element",
    value: fields.FUND_NAME,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "№ дела",
    tooltip: "Номер дела",
    className: "info-modal__element",
    value: fields.CASE_NUMBER,
    linkRights: [authorities.CASE_LIST],
    isLink: true,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата хранения ЭАД",
    tooltip: "Дата хранения ЭАД",
    className: "info-modal__element",
    value: fields.STORAGE_EXPIRATION_DATE,
  },
  {
    id: uuid(),
    isEdited: true,
    title: "Комментарий",
    tooltip: "Поле доступное для редактирования пользователю с соответствующими правами",
    className: "info-modal__element_full-size info-modal__element_textarea",
    value: fields.COMMENT,
    fieldType: "textarea",
    maxLength: "20000",
    mask: cloneDeep(standardMask.maskNotStartWithSpace),
  },
];

export const paperCaseElements = [
  {
    id: uuid(),
    title: "Статус",
    tooltip: "Статус",
    className: "info-modal__element_title",
    value: fields.STORAGE_STATUS,
  },
  {
    id: uuid(),
    title: "Примечание к статусу",
    tooltip: "Примечание к статусу",
    placeholder: "Укажите примечание к статусу",
    className: "info-modal__element_title",
    value: fields.STORAGE_STATUS_COMMENT,
  },
  {
    id: uuid(),
    title: "Корпус",
    tooltip: "Корпус",
    className: "info-modal__element",
    value: fields.STORAGE_HOUSE,
  },
  {
    id: uuid(),
    title: "Этаж (ярус)",
    tooltip: "Этаж (ярус)",
    className: "info-modal__element",
    value: fields.STORAGE_FLOOR,
  },
  {
    id: uuid(),
    title: "Архивохранилище",
    tooltip: "Архивохранилище",
    className: "info-modal__element",
    value: fields.STORAGE_ARCHIVE_STORAGE,
  },
  {
    id: uuid(),
    title: "Помещение",
    tooltip: "Помещение",
    className: "info-modal__element",
    value: fields.STORAGE_ROOM,
  },
  {
    id: uuid(),
    title: "Стеллаж",
    tooltip: "Стеллаж",
    className: "info-modal__element",
    value: fields.STORAGE_SHELVING,
  },
  {
    id: uuid(),
    title: "Шкаф",
    tooltip: "Шкаф",
    className: "info-modal__element",
    value: fields.STORAGE_CABINET,
  },
  {
    id: uuid(),
    title: "Полка",
    tooltip: "Полка",
    className: "info-modal__element",
    value: fields.STORAGE_SHELF,
  },
  {
    id: uuid(),
    title: "Дата передачи",
    tooltip: "Дата передачи",
    className: "info-modal__element",
    value: fields.STORAGE_DATE,
  },
  {
    id: uuid(),
    title: "Примечание к месту хранения",
    tooltip: "Примечание к месту хранения",
    placeholder: "Укажите примечание",
    className: "info-modal__element_title",
    value: fields.STORAGE_COMMENT,
  },
];

export const mrpElements = [
  {
    id: uuid(),
    isEdited: false,
    title: "Номер доверенности",
    tooltip: "Номер доверенности",
    className: "info-modal__element_title",
    value: "mrdPowerAttorneyNumber",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата доверенности",
    tooltip: "Дата доверенности",
    className: "info-modal__element",
    value: "mrdCreationDate",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Срок действия",
    tooltip: "Срок действия",
    className: "info-modal__element",
    value: "mrdExpiredAt",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Наименование",
    tooltip: "Наименование доверителя",
    className: "info-modal__element_title",
    value: "mrdTrusteeCompanyName",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "ИНН",
    tooltip: "ИНН доверителя",
    className: "info-modal__element",
    value: "mrdTrusteeInn",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "ОГРН",
    tooltip: "ОГРН доверителя",
    className: "info-modal__element",
    value: "mrdTrusteeOgrn",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "ФИО лица, действующего без доверенности",
    tooltip: "ФИО лица, действующего без доверенности",
    className: "info-modal__element_title",
    value: "mrdTrusteeFio",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "ФИО представителя",
    tooltip: "ФИО представителя",
    className: "info-modal__element_title",
    value: "mrdRepresentativeFio",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Полномочия представителя по доверенности",
    tooltip: "Полномочия представителя по доверенности",
    className: "info-modal__element_title",
    value: "mrdPermissions",
    isShowContentTooltip: true,
  },
];

export const techElements = [
  {
    id: uuid(),
    isEdited: false,
    title: "Пользователь, изменивший запись",
    className: "info-modal__element_title",
    value: fields.COMMENT_UPDATE_USER,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата изменения",
    className: "info-modal__element_title",
    value: fields.COMMENT_UPDATE_DATE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Идентификатор",
    className: "info-modal__element_title",
    value: fields.EAD_ID,
  },
];

export const fullModalElements = [...modalElements, ...techElements, ...mrpElements];
