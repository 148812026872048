










































































import { defineComponent } from "@vue/composition-api";
import Breadcrumbs from "@monorepo/uikit/src/components/common/Breadcrumbs.vue";
import FiltersNew from "@monorepo/uikit/src/components/common/FiltersNew.vue";
import SortPanelContainerNew from "@monorepo/uikit/src/components/common/SortPanelContainerNew.vue";
import TextFilter from "@monorepo/uikit/src/components/tableViews/TextFilter.vue";
import SelectFilter from "@monorepo/uikit/src/components/common/Select/SelectFilter.vue";
import { viewTitle } from "@monorepo/utils/src/variables/projectsData/eadView/viewTitle";
import { tableHeaders, newTableHeaders } from "@monorepo/utils/src/variables/projectsData/eadView/tableHeaders";
import { convertApiItemToUi } from "@monorepo/inventory/src/views/EadView/utils/convertApiItemToUi";
import InfiniteScroll from "@monorepo/uikit/src/components/tableViews/InfiniteScroll.vue";
import ScrollPanel from "@monorepo/uikit/src/components/tableViews/ScrollPanel.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import TableSearchResults from "@monorepo/uikit/src/components/common/TableSearchResults.vue";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import PreviewTableCard from "@monorepo/inventory/src/components/newView/PreviewTableCard.vue";
import { fields } from "@monorepo/utils/src/variables/projectsData/eadView/fields";
import { convertToSelectItems } from "@monorepo/utils/src/utils/convertToSelectItems";
import { IDocSection } from "@monorepo/catalog/src/views/DocTypeView/types/docTypeElement";
import { convertApiTreeItemToUi } from "@monorepo/catalog/src/views/DocTypeView/utils/convertApiItemToUi";
import { filtersElements } from "@monorepo/utils/src/variables/projectsData/eadView/filtersElements";
import { currentFilters as setCurrentFilters } from "@monorepo/inventory/src/views/EadView/utils/defaultCurrentFilters";
import FindButton from "@monorepo/uikit/src/components/tableViews/FindButton.vue";
import { SORT_TYPE } from "@monorepo/utils/src/types/sortTypes";
import { ViewMode } from "@monorepo/utils/src/types/viewMode";

export default defineComponent({
  components: {
    Breadcrumbs,
    TextFilter,
    SelectFilter,
    InfiniteScroll,
    ScrollPanel,
    TableSearchResults,
    PreviewTableCard,
    FiltersNew,
    FindButton,
    SortPanelContainerNew,
  },
  props: {
    sectionName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      SORT_TYPE,
      viewTitle,
      search: "",
      items: [],
      sortFilter: null,
      tableHeaders,
      newTableHeaders,
      filtersElements,
      setCurrentFilters,
      autocompleteFiltersKeys: [fields.FILTER_KEYWORD],
      chipboardItems: [
        {
          title: "Присутствует",
          value: true,
        },
        {
          title: "Отсутствует",
          value: false,
        },
      ],
      additionalSort: {
        documentTypeIndex: ["documentTypeSectionIndex", "documentTypeIndex"],
      },
    };
  },
  computed: {
    ...mapGetters("auth", ["isOpenEducation", "user", "userSettings"]),
    ...mapGetters("eadView", [
      "cells",
      "data",
      "openedId",
      "isOpenFilters",
      "isSelectAll",
      "infiniteId",
      "totalLength",
      "selectedIds",
      "isTableLoading",
      "autorefresh",
      "isLoadingChangeAutorefresh",
      "sectionsList",
      "viewMode",
    ]),
    currentDocumentView(): ViewMode {
      return this.viewMode?.typeDisplay || ViewMode.TABLE;
    },
    headers(): typeof newTableHeaders {
      return this.newTableHeaders;
    },
    resultData(): any {
      return (this.data as any).map(convertApiItemToUi);
    },
    resultFiltersElement(): any {
      return this.filtersElements.slice(1);
    },
    statusOptions(): { title: string; value: string }[] {
      return convertToSelectItems(["Получен", "Не получен", "Удалён"]);
    },
    sectionsTreeItems(this: { sectionsList: IDocSection[] }) {
      return (this.sectionsList || []).sort((a, b) => a.orderIndex - b.orderIndex).map((item) => convertApiTreeItemToUi(item, false, true));
    },
    selectItemsObj(): Record<string, unknown> {
      return {
        chipboardItems: this.chipboardItems,
        statusOptions: this.statusOptions,
        sectionsTreeItems: this.sectionsTreeItems,
      };
    },
  },
  methods: {
    ...mapActions("eadView", ["getEventList", "getExportData", "addOpenedId", "setIsOpenFilters", "getTreeData", "clearFilters", "setAutorefresh"]),
    ...mapMutations("eadView", ["refreshData", "setSelectedIds"]),
    toggleModal(value: boolean) {
      if (!value) {
        this.$store.dispatch(`eadView/addOpenedId`, null);
      }
    },
    togglePdf(element: any) {
      this.$emit("changeIsShowPdf", true);
      const files = element.files;
      const mainId = (element?.files || []).find((file: any) => !file.attachedTo)?.id || null;
      const firstValidId = (element?.files || []).find((file: any) => file.previewStatus?.code || file.pages?.length)?.id || null;
      const fileId = mainId || firstValidId;
      this.$emit("changeFiles", { files, fileId });

      if (!fileId) {
        this.$emit("changeIsShowPdf", false);
        showNotification("Отсутствуют файлы для просмотра.");
      }
    },
    openModal(header: { value: string }, event: Record<string, unknown>) {
      this.$emit("openModal", { header, event });
    },
  },
});
