import { ComponentInstance, onBeforeMount } from "@vue/composition-api";

const useGetTableLibs = (root: ComponentInstance): void => {
  const store = root.$store;
  const isOpenModalOnStart = !!root.$route.query.isOpenModal;

  onBeforeMount(() => {
    store.dispatch("eadView/getOiks");
    store.dispatch("eadView/getStatuses");
    store.dispatch("eadView/getSectionList");
    store.dispatch("eadView/getElasticValue");
    store.dispatch("eadView/getViewMode", isOpenModalOnStart);
    //store.dispatch("eadView/getKinds");
    //store.dispatch("eadView/getPaperStatuses");
  });
};

export default useGetTableLibs;
