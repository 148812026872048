import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { convertAutocompleteItemToApi, convertTreeItemsToApi, formDateAndTimeFilter } from "@monorepo/utils/src/api/convertFiltersToApi";
import { fields } from "@monorepo/utils/src/variables/projectsData/eadView/fields";

export const convertFiltersCustomToApi = (
  resultFilters: ITableFiltersObject & {
    fieldFilters: {
      creationDateFrom?: string;
      creationDateTo?: string;
      creationTimeFrom?: string;
      creationTimeTo?: string;
      status?: string;
    };
  }
): void => {
  formDateAndTimeFilter(resultFilters, "creationDate", "creationTime");
  convertAutocompleteItemToApi(resultFilters, fields.FILTER_FUND, `fieldFilters.${fields.FILTER_FUND}.number`);
  convertAutocompleteItemToApi(resultFilters, fields.FILTER_OIK, `fieldFilters.${fields.FILTER_OIK}.code`);
  convertTreeItemsToApi(resultFilters, fields.FILTER_DOC_TYPE, "id");
  delete resultFilters.fieldFilters.keyword;
  delete resultFilters.fieldFilters[fields.FILTER_SEARCH_MORPH];
  delete resultFilters.fieldFilters[fields.FILTER_SEARCH_NGRAMED];
  delete resultFilters.fieldFilters[fields.FILTER_SEARCH_SYNONYM];
  delete resultFilters.fieldFilters[fields.FILTER_SEARCH_FREQUENCY];
};

export const convertSearchFiltersCustomToApi = (
  resultFilters: ITableFiltersObject & {
    fieldFilters: {
      creationDateFrom?: string;
      creationDateTo?: string;
      creationTimeFrom?: string;
      creationTimeTo?: string;
      status?: string;
    };
  }
): void => {
  formDateAndTimeFilter(resultFilters, "creationDate", "creationTime");
  convertAutocompleteItemToApi(resultFilters, fields.FILTER_FUND, `fieldFilters.${fields.FILTER_FUND}.number`);
  convertAutocompleteItemToApi(resultFilters, fields.FILTER_OIK, `fieldFilters.${fields.FILTER_OIK}.code`);
  convertTreeItemsToApi(resultFilters, fields.FILTER_DOC_TYPE, "id");
};

export const convertTreeFiltersCustomToApi = (
  resultFilters: ITableFiltersObject & {
    fieldFilters: {
      status?: string;
    };
  }
): void => {
  convertAutocompleteItemToApi(resultFilters, fields.FILTER_FUND, `fieldFilters.${fields.FILTER_FUND}.code`);
  convertAutocompleteItemToApi(resultFilters, fields.FILTER_OIK, `fieldFilters.${fields.FILTER_OIK}.code`);
  resultFilters.fieldFilters.code = resultFilters.fieldFilters[fields.FILTER_FUND];
  delete resultFilters.fieldFilters[fields.FILTER_FUND];
};
