import { v4 as uuid } from "uuid";
import { fields } from "@monorepo/utils/src/variables/projectsData/eadView/fields";
import { headerCheckboxObject } from "@monorepo/utils/src/variables/headerCheckboxValue";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { IEadElement } from "@monorepo/inventory/src/views/EadView/types/eadElement";

export const tableHeaders = [
  headerCheckboxObject(),
  {
    text: "№ документа",
    tooltip: "Регистрационный номер документа в СЭД",
    defaultWidth: 220,
    value: fields.EAD_NUMBER,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Дата регистрации",
    tooltip: "Дата регистрации документа в СЭД",
    defaultWidth: 170,
    value: fields.REGISTRATION_DATE,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Статус",
    tooltip: "Статус ЭАД может принимать значения: Не получен, Получен, Удалён",
    defaultWidth: 90,
    value: fields.EAD_STATUS,
    isSorted: true,
    isHandle: true,
    id: uuid(),
    sortField: "status",
    slotName: "eadStatus",
  },
  {
    text: "Заголовок",
    tooltip: "Заголовок документа",
    defaultWidth: 220,
    value: fields.EAD_TITLE,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Архивный шифр",
    tooltip: "Архивный шифр документа",
    defaultWidth: 160,
    value: fields.EAD_ARCHIVE_CIPHER,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Архивный шифр дела",
    tooltip: "Архивный шифр дела, с которым связан ЭАД",
    defaultWidth: 170,
    value: fields.EAD_CASE_ARCHIVE_CIPHER,
    sortField: "caseEntity.archiveCipher",
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Индекс дела",
    tooltip: "Индекс дела, с которым связан ЭАД",
    defaultWidth: 180,
    value: fields.EAD_CASE_INDEX,
    sortField: "caseEntity.index",
    linkRights: [authorities.CASE_LIST],
    isSorted: true,
    isHandle: true,
    isLink: true,
    id: uuid(),
  },
  {
    text: "Заголовок дела",
    tooltip: "Заголовок дела, с которым связан ЭАД",
    defaultWidth: 200,
    value: fields.EAD_CASE_TITLE,
    sortField: "caseEntity.header",
    linkRights: [authorities.CASE_LIST],
    isSorted: true,
    isHandle: true,
    isLink: true,
    id: uuid(),
  },
  {
    text: "ОИК",
    tooltip: "Краткое наименование ОИК",
    defaultWidth: 160,
    value: fields.OIK_SHORT_NAME,
    sortField: "caseEntity.oikName",
    linkRights: [authorities.OIK_LIST],
    isSorted: true,
    isHandle: true,
    isLink: true,
    id: uuid(),
  },
  {
    text: "Фонд",
    tooltip: "Номер фонда",
    defaultWidth: 160,
    value: fields.FUND_NUMBER,
    sortField: "caseEntity.fundNumber",
    linkRights: [authorities.FUND_LIST],
    isSorted: true,
    isHandle: true,
    isLink: true,
    id: uuid(),
  },
  {
    text: "Идентификатор вида документа",
    tooltip: "Идентификатор вида документа",
    defaultWidth: 160,
    value: fields.DOC_TYPE_ID,
    sortField: "caseEntity.fundNumber",
    isSorted: true,
    isHandle: true,
    isLink: true,
    id: uuid(),
  },
  {
    text: "Наименование секции (перечень видов)",
    tooltip: "Наименование секции вида документа",
    defaultWidth: 300,
    value: fields.DOC_SECTION,
    sortField: "documentTypeSectionIndex",
    isSorted: true,
    isHandle: true,
    isLink: false,
    id: uuid(),
  },
  {
    text: "Наименование документа (перечень видов)",
    tooltip: "Наименование документа вида документа",
    defaultWidth: 250,
    value: fields.DOC_DOC,
    sortField: "documentTypeIndex",
    isSorted: true,
    isHandle: true,
    isLink: false,
    id: uuid(),
  },
  {
    text: "Дата поступления",
    tooltip: "Дата поступления в ЦХЭД",
    defaultWidth: 170,
    value: fields.EAD_CREATION_DATE,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Вид носителя",
    tooltip: "Вид носителя может принимать значения: Бумажный, Электронный",
    defaultWidth: 160,
    value: fields.EAD_TYPE,
    sortField: "caseEntity.storageKind",
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "ДСП",
    tooltip: "Признак документа для служебного пользования",
    defaultWidth: 120,
    value: fields.EAD_RESTRICTED,
    sortField: "dsp",
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
];

export const newTableHeaders = [
  {
    text: "№ документа",
    tooltip: "Регистрационный номер документа в СЭД",
    defaultWidth: 220,
    value: fields.EAD_NUMBER,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Дата регистрации",
    tooltip: "Дата регистрации документа в СЭД",
    defaultWidth: 170,
    value: fields.REGISTRATION_DATE,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Статус",
    tooltip: "Статус ЭАД может принимать значения: Не получен, Получен, Удалён",
    defaultWidth: 90,
    value: "status",
    isSorted: true,
    isHandle: true,
    id: uuid(),
    sortField: "status",
    slotName: "eadStatus",
  },
  {
    text: "Заголовок",
    tooltip: "Заголовок документа",
    defaultWidth: 220,
    value: fields.EAD_TITLE,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Заголовок дела",
    tooltip: "Заголовок дела, с которым связан ЭАД",
    defaultWidth: 200,
    value: fields.EAD_CASE_TITLE,
    sortField: "caseEntity.header",
    linkRights: [authorities.CASE_LIST],
    isSorted: true,
    isHandle: true,
    isLink: true,
    id: uuid(),
  },
  {
    text: "ОИК",
    tooltip: "Краткое наименование ОИК",
    defaultWidth: 160,
    value: fields.OIK_SHORT_NAME,
    sortField: "caseEntity.oikName",
    linkRights: [authorities.OIK_LIST],
    isSorted: true,
    isHandle: true,
    isLink: true,
    id: uuid(),
  },
  {
    text: "Фонд",
    tooltip: "Номер фонда",
    defaultWidth: 160,
    value: fields.FUND_NUMBER,
    sortField: "caseEntity.fundNumber",
    linkRights: [authorities.FUND_LIST],
    isSorted: true,
    isHandle: true,
    isLink: true,
    id: uuid(),
  },
  {
    text: "Идентификатор вида документа",
    tooltip: "Идентификатор вида документа",
    defaultWidth: 160,
    value: fields.DOC_TYPE_ID,
    sortField: "caseEntity.fundNumber",
    isSorted: true,
    isHandle: true,
    isLink: true,
    isShowTooltip: true,
    tooltipCb: (item: IEadElement): string => {
      const section = item[fields.DOC_SECTION as keyof IEadElement];
      const document = item[fields.DOC_DOC as keyof IEadElement];
      return (section as string)?.trim() || (document as string)?.trim()
        ? `${item[fields.DOC_SECTION as keyof IEadElement]}. ${item[fields.DOC_DOC as keyof IEadElement]}`
        : "";
    },
    id: uuid(),
  },
  {
    text: "Дата поступления",
    tooltip: "Дата поступления в ЦХЭД",
    defaultWidth: 170,
    value: fields.EAD_CREATION_DATE,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Вид носителя",
    tooltip: "Вид носителя может принимать значения: Бумажный, Электронный",
    defaultWidth: 160,
    value: fields.EAD_TYPE,
    sortField: "caseEntity.storageKind",
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "ДСП",
    tooltip: "Признак документа для служебного пользования",
    defaultWidth: 120,
    value: fields.EAD_RESTRICTED,
    sortField: "dsp",
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
];
